.mover {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: rgb(151, 181, 238);
  transition: transform 0.5s ease;
}

.background {
  display: flex;
}

.circle {
  width: 8px;
  height: 8px;
  background-color: #ddd;
  position: relative;
  border-radius: 4px;
  margin-right: 8px;
  overflow: hidden;
}